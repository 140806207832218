import { CryptoJS } from './crypto.min.js'
import storage from 'good-storage'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
// import http from '../services/http'
// import urls from '../utils/urls'
// import { message } from 'antd'

// const Message = message

// 用户相关数据
const globalData = {
  CartInfo: {
    User: null,
  },
}

// const key = CryptoJS.enc.Utf8.parse('th!s!s@p@ssw0rd;setoae$12138!@$@') // 32 位字符串作为密钥
const env = process.env.BUILD_ENV

const iv = CryptoJS.enc.Utf8.parse('-o@g*m,%0!si^fo1') // 16 位字符串作为密钥偏移量

// insights 监控
let connectionString
if (env === 'testing' || env === 'prod') {
  connectionString =
    'InstrumentationKey=9beeb791-6da5-caef-a6e8-90b2b8fcb43a;EndpointSuffix=applicationinsights.azure.cn;IngestionEndpoint=https://chinaeast2-0.in.applicationinsights.azure.cn/'
} else if (env === 'demo') {
  connectionString =
    'InstrumentationKey=10321ec9-a259-cf88-95a1-de68e48d6878;EndpointSuffix=applicationinsights.azure.cn;IngestionEndpoint=https://chinaeast2-0.in.applicationinsights.azure.cn/'
} else {
  connectionString =
    'InstrumentationKey=511d841e-0867-ccbb-a2e4-90bb034700ba;EndpointSuffix=applicationinsights.azure.cn;IngestionEndpoint=https://chinaeast2-0.in.applicationinsights.azure.cn/'
}

const appInsights = new ApplicationInsights({
  config: {
    connectionString,
  },
})
appInsights.loadAppInsights()
appInsights.trackPageView()

function isAssetTypeAnImage(ext) {
  return (
    ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(
      ext.toLowerCase()
    ) !== -1
  )
}

export default {
  getStorage(item, type) {
    if (type === 'session') {
      return storage.session.get(item)
    } else {
      return storage.get(item)
    }
  },

  setStorage(item, data, type) {
    if (type === 'session') {
      return storage.session.set(item, data)
    } else {
      return storage.set(item, data)
    }
  },

  removeStorage(item, type) {
    if (type === 'session') {
      return storage.session.remove(item)
    } else {
      return storage.remove(item)
    }
  },
  getGD(item) {
    return globalData[item]
  },

  setGD(item, data) {
    globalData[item] = data
  },

  checkUrlIsImg(url) {
    if (url) {
      var filePath = url
      // 获取最后一个.的位置
      var index = filePath.lastIndexOf('.')
      // 获取后缀
      var ext = filePath.substr(index + 1)
      // 判断是否是图片
      // console.log('该文件是否为图片：' + )
      return isAssetTypeAnImage(ext)
    } else {
      return false
    }
  },
  /**
   * randomKey 产生指定长度随机字母数字组合
   * @param {*} range
   * @returns {string}
   */

  randomKey(range) {
    range = range || 32

    let string = ''
    const arr = 'abcdefghijklmnopqrstuvwxyz0123456789@!;'

    for (var i = 0; i < range; i++) {
      const pos = Math.round(Math.random() * (arr.length - 1))
      string += arr[pos]
    }

    return string
  },

  // MD5 加密
  md5(str) {
    return CryptoJS.MD5(str).toString()
  },

  // 加密方法
  encryptAES(param, data) {
    let encryptedQuery = ''
    let encryptedData = ''
    const key = this.randomKey() // 每次请求随机生成 key

    // query
    if (param) {
      encryptedQuery = this.encrypt(param, key)
    }

    // data
    if (data) {
      encryptedData = this.encrypt(data, key)
    }

    return {
      query: encryptedQuery,
      data: encryptedData,
      key: key,
    }
  },

  // AES 加密方法
  encrypt(word, key) {
    key = key || 'th!s!s@p@ssw0rd;setoae$12138!@$@'

    let encrypted

    encrypted = CryptoJS.AES.encrypt(word, CryptoJS.enc.Utf8.parse(key), {
      iv: iv,
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.Pkcs7,
    })

    encrypted = encrypted.toString()
    encrypted = encrypted.replace(/=/g, '*')
    encrypted = encrypted.replace(/\+/g, '$')
    encrypted = encodeURIComponent(encrypted)

    return encrypted
  },

  // AES 解密方法
  decrypt(word, key) {
    let decodeWord = decodeURIComponent(word)
    key = key || 'th!s!s@p@ssw0rd;setoae$12138!@$@'
    decodeWord = decodeWord.replace(/\*/g, '=')
    decodeWord = decodeWord.replace(/\$/g, '+')

    const decrypted = CryptoJS.AES.decrypt(
      decodeWord,
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: iv,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.Pkcs7,
      }
    )

    return CryptoJS.enc.Utf8.stringify(decrypted)
  },
  // gagdas
  getISOTime(date) {
    const timeMills = Date.parse(date)
    const tzoffset = new Date().getTimezoneOffset() * 60000
    const imetZoneTime = new Date(timeMills - tzoffset)
      .toISOString()
      .slice(0, -1)
    return imetZoneTime
  },
  exactNumber(number) {
    const eNumber = Number(number).toFixed(2)
    return eNumber
  },
  // 获取当前登录人页面按钮权限
  // getAuthBtns(moduleId) {
  //   http
  //     .get(urls.LOGINAPI + '/Auth/UserInfo', {
  //       systemCode: 'STORE',
  //       moduleId: moduleId
  //     })
  //     .then((res) => {
  //       const { isSuccess, result, message } = res.data
  //       const { privilegeTree } = result || []
  //       if (!isSuccess) {
  //         Message.error(message)
  //       }
  //       return privilegeTree
  //     })
  //     .catch((err) => {
  //       console.error(err)
  //     })
  // }

  // 判断是否满足审核条件
  checkAudit(employeeNextAudits, employeeNextAudit) {
    let flag = false
    const userId = storage.get('userInfo')?.idNumber
    if (employeeNextAudits && employeeNextAudits.length > 0) {
      flag = employeeNextAudits.some((item) => item.employeeNoAudit === userId)
    }
    if (employeeNextAudit === userId) {
      flag = true
    }
    return flag
  },

  // 金额添加分位符
  formatCurrency(value) {
    if (value) {
      const number = Number(value)
      return new Intl.NumberFormat('zh-CN', {
        minimumFractionDigits: 2,
      }).format(number)
    } else {
      return Number(0)
    }
  },
}
// 金额添加分位符
export function formatCurrency(value) {
  if (value || value === 0) {
    const number = Number(value)
    return new Intl.NumberFormat('zh-CN', {
      minimumFractionDigits: 2,
    }).format(number)
  } else {
    return null
  }
}
